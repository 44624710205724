/* Global styles */
body {
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

/* Map styles */
.mapmarker, .usermarker {
    position: relative;
}

.mapmarker1, .mapmarker2, .mapmarker3, .mapmarker4, .mapmarker5 {
    border-radius: 6px;
    color: #dee2e6;
    font-size: 14px;
    padding: 0px 3px 2px 3px;
    border: 2px solid var(--ion-color-primary-contrast);
}

.mapmarker1 {
    background-color: var(--ion-color-primary);
}

.mapmarker2 {
    background-color: var(--ion-color-secondary);
}

.mapmarker3 {
    background-color: var(--ion-color-tertiary);
}

.mapmarker4 {
    background-color: var(--ion-color-warning);
}

.mapmarker5 {
    background-color: var(--ion-color-medium);
}

.usermarker {
    background-color: var(--ion-color-primary-shade);
    border-radius: 10px;
    height: 20px;
    width: 20px;
    border: 5px solid var(--ion-color-primary-tint);
}

/* Overlay styles */
.overlay-content {
    background-color: rgba(0, 0, 0, 0.8);
    display: flex;
    color: var(--ion-color-primary-contrast);
    padding: 2px 5px;
    max-width: 200px;
    font-size: 14px;
    border: 2px solid var(--ion-color-primary-contrast);
    border-radius: 6px;
    animation: fadeIn 400ms ease-in-out;
    z-index: 999;
}

.overlay-head {
    cursor: pointer;
    padding: 4px 0px 0px 0px;
    text-align: center;
    font-size: 16px;
}

.marker-style:hover {
    filter: drop-shadow(0 0 4px rgba(0, 0, 0, .3));
}

/* Nearest styles */
.nearest {
    position: fixed;
    bottom: 0;
    left: 0;
    color: var(--ion-color-primary-contrast);
    background-color: var(--ion-color-primary);
    padding: 10px;
    margin: 10px;
    border-radius: 6px;
    font-size: 18px;
    box-shadow: gray 0 0 4px;
}

/* Modal styles */
.pk-modal, .pk-modal-search {
    /*--height: 50%;*/
    --width: 95%;
    --max-width: 460px;
    /*--max-height: 500px;*/
    --border-radius: 16px;
    --box-shadow: 0 10px 15px -3px rgb(0 0 0 / 0.1), 0 4px 6px -4px rgb(0 0 0 / 0.1);
}

.pk-modal::part(backdrop), .pk-modal-search::part(backdrop) {
    background: rgba(209, 213, 219);
    opacity: 1;
}

.pk-modal ion-toolbar, .pk-modal-search ion-toolbar {
    --background: var(--ion-color-primary);
    --color: var(--ion-color-primary-contrast);
}

.pk-modal-search {
    --max-height: 400px;
}

.centered-content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
}

.overlay-close {
    background: white;
    position: absolute;
    right: -10px;
    color: black;
    top: -10px;
    width: 20px;
    height: 20px;
    border-radius: 25px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    font-size: 18px;
}

ion-content {
    --padding-bottom: 5px;
    --padding-end: 5px;
    --padding-start: 10px;
    --padding-top: 5px;
}


.purchase-modal {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 77px; /* Adjust this value based on the height of your bottom navigation bar */
    /*background: white;*/
}

#container {
    text-align: center;
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    height: 100%;
}

#container strong {
    font-size: 20px;
    line-height: 26px;
}

#container p {
    font-size: 16px;
    line-height: 22px;
    color: #8c8c8c;
    margin: 0;
}

#container a {
    text-decoration: none;
}

#last_request_card {
    height: 50%;
    overflow: scroll;
}

#button_actions {
    height: 50%;
    overflow: scroll;
    padding-bottom: 100px;
}

#last_request_content {
    text-align: left;
    font-size: 10px;
    line-height: 16px;
}

.paywall-container {
    background-color: blue;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
}

.large-bold-text {
    font-size: 1.5em;
    font-weight: bold;
    text-align: center;
    margin-top: 5px;
    padding-top: 5px;
    padding-bottom: 5px;

}

.medium-bold-text {
    font-size: 1.2em;
    font-weight: bold;
    text-align: center;
    /*margin-top: 10px;*/
}

.small-bold-text {
    font-size: 0.8em;
    font-weight: bold;
    text-align: center;
    /*margin-top: 10px;*/
}

.small-text {
    font-size: 0.8em;
    text-align: center;
    /*margin-top: 10px;*/
}

.text-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    color: #002d87;
    width: 100%;
}


.info-container {
    display: flex;
    flex-direction: column;
    color: #002d87;
    width: 100%;
}

.button-container {
    width: 100%;
    justify-content: center;
    margin-bottom: 10px;
    padding: 5px;
}


ion-radio {
    min-height: 90px;
    color: #002d87;
}

ion-card {
    border-radius: 14px;
    color: #002d87;
}

ion-card-header {
    color: #002d87;
    font-size: large;
    font-weight: bold;
    flex-direction: row;
}

ion-card-content {
    border-radius: 0 0 14px 14px;
}

ion-img {
    justify-content: center;
    margin: 5px auto 0;
    --size: 80px;
    max-width: 80px; /* Set the maximum width */
    max-height: 80px; /* Set the maximum height */
}

ion-icon {
    font-size: 1.5em;
    margin: -2px 5px 0 0;
}

ion-row {
    border-top: 1px solid #bbccf1;
    margin-top: 5px;
    padding-top: 5px;

}

/*ion-button {*/
/*    display: flex;*/
/*    justify-content: center;*/
/*    align-items: center;*/
/*    margin: 0 auto; !* Center horizontally *!*/
/*    width: auto; !* Ensure the button does not occupy the entire space *!*/
/*}*/

/*.icon-text {*/
/*    display: flex;*/
/*    align-items: center;*/
/*}*/

/*.icon-text ion-icon {*/
/*    margin-right: 8px; !* Adjust the spacing as needed *!*/
/*}*/

.icon-text {
    display: flex;
    align-items: center;
    padding: 3px;
}

.icon-text ion-icon {
    margin-right: 8px;
    flex-shrink: 0;
    flex-grow: 0;
}